<style scoped>
.recorder-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .3s, transform .3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.record-button {
  background-color: #28a745;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: -50;
}

.stop-button {
  background-color: #dc3545;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
}

.audio-player {
  width: 100%;
}

.audio-item {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
<script>
import Swal from 'sweetalert2'

import {reqMethods} from '../../../state/helpers';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {required, helpers} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from 'axios';
import {firebase} from "../../../services/firebase";

/**
 *
 */
export default {
  setup() {
    return {
      zoom: 12,
      maxZoom: 25,
      center: {
        lat: null,
        lng: null
      },
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      v$: useVuelidate()
    };
  },
  components: {
    Layout,
    PageHeader,

  },
  data() {
    return {
      chatMessagesData: [],
      title: "Infos de l'intervention",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Informations",
          active: true,
        },
      ],
      alertId: null,
      intervention: {},
      photos: {},
      audios: {},
      audiosReports: {},
      position: {},
      nbre_arr: "",
      nbre_arm: "",
      nbre_veh: "",
      showModal: false,
      showP2: false,
      submitted: false,
      ExId: null,
      exchange: {},
      form: {
        message: "",
      },
      staticAnchor: [16, 37],
    };
  },
  validations: {
    nbre: {
      required: helpers.withMessage("Number is required", required),
    },
    victime: {
      required: helpers.withMessage("Victime is required", required),
    },
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    async getAgentPositionFromFirestore() {
      const self = this;
      firebase.firestore()
          .collection("partages_position")
          .onSnapshot(snap => {
            const agentsPosition = [];
            const infoWindows = [];
            const iconCommon = {
              url: "/maps/gpscar.png",
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0, 0),
            };

            const icons = {
              moto: {...iconCommon, url: "/maps/gpsmoto.png"},
              car: {...iconCommon, url: "/maps/gpscar.png"},
              pieds: {...iconCommon, url: "/maps/mapuserwalker.png"},
              helico: {...iconCommon, url: "/maps/gpscar.png"},
            };

            console.log("snap.docs.length")
            console.log(snap.docs.length)
            for (let i = 0; i < snap.docs.length; i++) {
              var driver = snap.docs[i].data();
              const finalIcon = icons[driver.type_service] || iconCommon;
              if (this.intervention.agent.serial_number === driver.serialNumber) {
                const nmaker =
                    new google.maps.Marker({
                      map: this.map,
                      title: driver.type_service,
                      icon: finalIcon
                    })


                agentsPosition.push(nmaker);
                agentsPosition[i].setPosition(
                    new google.maps.LatLng(driver.latitude, driver.longitude)
                );
                console.log(driver)
                infoWindows.push(new google.maps.InfoWindow({
                  disableAutoPan: true
                }));
                /* infoWindows[i].setContent(
                   `<div class="ui header">Agent A </div>`
               );
               infoWindows[i].open(this.map, agentsPosition[i]);*/

              }
            }
          });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },
    getInterventionInfos() {
      const that = this;
      const id = {id: parseInt(this.$route.params.id)};
      this.postRequest({
        route: '/intervention/intervention/findbyid/',
        data: id,
      }).then(
          function (response) {
            const data = response.data;
            that.intervention = data.intervention[0];
            that.photos = data.photos;
            that.audios = data.audios;
            that.audiosReports = data.audiosReports;
            that.alertId = that.intervention?.alert_spontaneous?.id;
            that.ExId = data.exchange[0]?.id;
            that.position = that.intervention?.position?.coordinates;
            that.getAgentPositionFromFirestore();
            that.getExchangeData();
            that.initMap();
          },
          function (error) {
            console.log(error);
          }
      )
    },
    CreateCenterControl(map){
      const controlButton = document.createElement("button");
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";

      controlButton.textContent = "Centré la carte";
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";
      controlButton.addEventListener("click", () => {
        map.setCenter({lat: this.position[0], lng: this.position[1] });
      });

      return controlButton;

    },
  initMap() {
      const self = this;
      console.log("init map")
      console.log(self.position)
      console.log(self.position[0])
      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(self.position[0], self.position[1]),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      new google.maps.Marker({
        position: {lat: self.position[0], lng: self.position[1] },
        map: this.map,
        title: "Position selectionnée",
      });

    const centerControlDiv = document.createElement("div");
    // Create the control.
    const centerControl = self.CreateCenterControl(this.map);

    // Append the control to the DIV.
    centerControlDiv.appendChild(centerControl);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

    /*this.map.addListener("click", (e) => {
      this.handleMapClick(e)
      this.handleMapClick(e)
    });*/
    },
    async getExchangeData() {
      const that = this;
      try {
        const response = await axios.get(`https://api.alcit.sims-technologie.com/api/v1/intervention/intervention/echange/message/?exchange=${that.ExId}`,
            {
              headers: {
                'Authorization': localStorage.getItem('token'),
              },
            });
        that.exchange = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    formSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: "Alcit agent",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        this.handleScroll();
      }
      this.submitted = false;
      this.form = {};
    },

    async setPertinence() {
      const formData = new FormData();
      formData.append('id', this.alertId);
      formData.append('pertinence', 'DECISIVE');
      try {
        const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },



    endIntervention(inter) {
      Swal.fire({
        title: "Cloture",
        text: "Cette intervention a t'elle été concluante?",
        imageHeight: 40,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui!",
      }).then(async result => {
        if (result.value) {
          this.showModal = true;
          const formData = new FormData();
          formData.append('id', inter.id);
          formData.append('status', 4);
          try {
            const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/intervention/intervention/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
              },
            });
            console.log(response);
            this.setPertinence();
            this.intervention.status = 4;
          } catch (error) {
            console.log(error);
          }
          console.log(inter);
        } else {
          console.log("Terminer la cloture !");
          this.intervention.status = 4;
        }

        this.getInterventionInfos();
      });
    },

    changeStatut(event) {
      this.showP2 = event.target.checked;
    },

    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      this.showModal = false;
      this.nbre = "";
      this.victime = "";
      this.submitted = false;
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Informations enregistrées",
        showConfirmButton: false,
        timer: 1500
      });
      this.getInterventionInfos();
    },
  },
  mounted() {
    this.getInterventionInfos();
    const that = this;
    /*if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }*/
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-modal v-model="showModal" title="Information sur l'intervention" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="nbre">Nombre d'arrestations</label>
              <input id="nbre" v-model="nbre_arr" type="text" class="form-control" placeholder="Nombre d'arrestation"/>
            </div>
            <div class="mb-3">
              <label for="nbre">Nombre de vehicule saisis</label>
              <input id="nbre" v-model="nbre_veh" type="text" class="form-control"
                     placeholder="CNombre de vehicule saisis"/>
            </div>
            <div class="mb-3">
              <label for="nbre">Nombre d'armes saisies</label>
              <input id="nbre" v-model="nbre_arm" type="text" class="form-control"
                     placeholder=">Nombre d'armes saisis"/>
            </div>

          </div>
          <!-- <div class="col-12">
            <div class="mb-3">
              <label for="name">Victimes</label>
              <input id="victime" v-model="victime" type="text" class="form-control"
                placeholder="Renseignez s'il y en a les victimes" :class="{
                  'is-invalid': submitted && v$.victime.$error,
                }" />
              <div v-if="submitted && v$.victime.$error" class="invalid-feedback">
                <span v-if="v$.victime.required.$message">{{
                  v$.victime.required.$message
                }}</span>
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <div class="mb-3">
              <label for="">Des vies ont-elles été sauvé ?</label>
              <input type="checkbox" class="float-end me-5" @click="changeStatut($event)">
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">Fermer</b-button>
          <b-button type="submit" variant="success" class="ms-1">Enregistrer</b-button>
        </div>
      </form>
    </b-modal>
    <div class="row mb-3">
      <div class="col-lg-12" v-if="intervention?.status != 4 && intervention?.status != 2">
        <b-button class="float-end" variant="success" @click="endIntervention(intervention)">Cloturer</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="d-flex mb-4">
                <img :src="`https://api.alcit.sims-technologie.com${intervention?.agent?.picture}`" alt
                     class="avatar-md rounded-circle img-thumbnail"/>
                <div class="flex-grow-1">
                  <h5 class="font-size-13 mt-1">{{ intervention?.agent?.name }} {{ intervention?.agent?.first_name }} </h5>
                  <small class="text-muted"> Agent </small>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Titre intervention:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.title }}</strong></h5>
                </div>
              </div>


              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Lieu:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.lieu }}</strong></h5>
                </div>
              </div>


              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Date:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.created_at?.split('T')[0] }}</strong></h5>

                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Heure:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.created_at?.split('T')[1]?.split('.')[0] }}</strong></h5>

                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Satut:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13"><span v-if="intervention?.status == 1" class="badge font-size-11 m-1 bg-info">
                    En cours
                  </span>
                    <span v-if="intervention?.status == 2" class="badge font-size-11 m-1 bg-success">
                    Terminée
                  </span>
                    <span v-if="intervention?.status == 3" class="badge font-size-11 m-1 bg-danger">
                    En Attente
                  </span>
                    <h4 v-if="intervention?.status == 4" class="m-1">
                      <i class="mdi mdi-check text-success"></i>
                      Cloturer
                    </h4></strong></h5>
                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Source d'information:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.information }}</strong></h5>
                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Message d'intervention:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ intervention?.description }}</strong></h5>
                </div>
              </div>



            </div>
          </div>

          <div v-if="intervention?.alert_spontaneous">
            <b-card no-body class="mb-1">
              <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                <h6 class="m-0">
                  <a class="text-dark" href="javascript: void(0);"><strong>  Details de l'alerte </strong></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row mt-3 justify-content-between">
                    <div class="col-md-2 text-primary">
                      <strong class="font-size-13">thematique:</strong>
                    </div>
                    <div class="col-md-10">
                      <h5><strong class="font-size-13">{{ intervention?.alert_spontaneous?.thematic?.name }}</strong></h5>
                    </div>
                  </div>
                  <div class="row mt-3 justify-content-between">
                    <div class="col-md-2 text-primary">
                      <strong class="font-size-13">Code:</strong>
                    </div>
                    <div class="col-md-10">
                      <h5><strong class="font-size-13">{{ intervention?.alert_spontaneous?.short_code }}</strong></h5>
                    </div>
                  </div>
                  <div class="row mt-3 justify-content-between">
                    <div class="col-md-2 text-primary">
                      <strong class="font-size-13">Pertinance:</strong>
                    </div>
                    <div class="col-md-10">
                      <h5><strong class="font-size-13">{{ intervention?.alert_spontaneous?.pertinence }}</strong></h5>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div v-if="audiosReports?.length > 0">
            <b-card no-body class="mb-1">
              <b-card-header v-b-toggle.accordion-5 header-tag="header" role="tab">
                <h6 class="m-0">
                  <a class="text-dark" href="javascript: void(0);"><strong>Rapport agent intervention </strong></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row">
               <div class="col-xl-4 col-6" v-for="(audior, index) in audiosReports" :key="index">
                      <div class="card">
                        <audio :src="`https://api.alcit.sims-technologie.com${audior.audio}`" controls></audio>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div v-if="audios?.length > 0">
            <b-card no-body class="mb-1">
              <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                <h6 class="m-0">
                  <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row">
                    <div class="col-xl-4 col-6" v-for="(audio, index) in audios" :key="index">
                      <div class="card">
                        <audio :src="`https://api.alcit.sims-technologie.com${audio.audio}`" controls></audio>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div v-if="photos?.length > 0">
            <b-card no-body class="mb-1">
              <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                <h6 class="m-0">
                  <a class="text-dark" href="javascript: void(0);">
                    <strong>Images </strong>
                  </a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row" v-viewer>
                    <div class="col-xl-2 col-6" v-for="(photo, index) in photos" :key=index>
                      <div class="card">
                        <img class="card-img-top img-fluid"
                             :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap"/>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>



        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-12">
        <div class="row">


        </div>
        <div class="row">
          <div class="col-lg-12">
            <section style="height: 700px;" ref="map" class="map"></section>
            <l-map :zoom="zoom" style="height: 642.4px;" :center="center">
              <l-tile-layer :url="url" :maxZoom="maxZoom"/>
              <l-marker v-if="Object.keys(position).length == 0" :visible="true" :lat-lng="position">
                <!-- v-for="( marker, index ) in Markers" :key="index" -->
                <l-icon :icon-anchor="staticAnchor">
                  <img src="@/assets/images/leaflet/marker-icon.png"/>
                </l-icon>
              </l-marker>
            </l-map>
          </div>
        </div>
      </div>

    </div>
  </Layout>
</template>
